const RECURRING_APPLICATION_CHARGE_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  ACTIVE: "active",
  DECLINED: "declined",
  EXPIRED: "expired",
  FROZEN: "frozen",
  CANCELLED: "cancelled"
};

const DEFAULT_PAGE_SIZE = 250;
const DEFAULT_API_VERSION = "2024-01";

module.exports = {
  DEFAULT_API_VERSION,
  DEFAULT_PAGE_SIZE,
  RECURRING_APPLICATION_CHARGE_STATUS
};
